function ParticipantCard(props) {
    const gameState = props.gameState

    const name = (props.name === null) ? <i>Unknown player</i> : props.name
    let estimate = (props.estimate === null || props.estimate === '' || props.estimate === undefined) ? null : props.estimate
    // eslint-disable-next-line
    if (parseInt(estimate) == estimate) {
        estimate = parseInt(estimate)
    }

    let estimateLabel, estimateLabelClass;
    let boxClass = ["box", "has-text-centered"];
    if (props.playerStatus === "observer") {
        estimateLabel = <small>Observer</small>
        estimateLabelClass = "has-text-grey"
        boxClass.push("has-background-light")
    } else if (gameState.reveal) {
        if (estimate === null) {
            estimateLabel = <small>Missing</small>
            estimateLabelClass = "has-text-danger"
        } else if (!Number.isInteger(estimate)) {
            estimateLabel = <small>{estimate}</small>
            estimateLabelClass = "has-text-danger"
        } else {
            estimateLabel = estimate
        }
    } else {
        if (estimate === null) {
            estimateLabel = "..."
            boxClass.push("has-background-warning-light")
        } else {
            estimateLabel = <small>Ready</small>
            boxClass.push("has-background-success-light")
        }
    }

    return (
        <div className="column is-half-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
            <div className={boxClass.join(" ")}>
                <p>{name}</p>
                <h1 className={estimateLabelClass}>{estimateLabel}</h1>
            </div>
        </div>
    );
}

export default ParticipantCard;