import React, { useEffect, useState } from 'react';
import './App.scss';
import { socket } from './socket';
import { ConnectionState } from './components/ConnectionState';
import PlayerControls from './components/PlayerControls';
import PhaseControls from './components/PhaseControls';
import Participants from './components/Participants';
import { COMMAND_SET_PLAYER_PROPERTIES, EVENT_CLEAR_ALL_ESTIMATES, EVENT_NEW_GAME_STATE, EVENT_NEW_PLAYER, EVENT_PLAYER_LEFT, EVENT_PLAYER_PROPERTIES_SET, EVENT_STARTUP } from './app/io-constants';

export const MAX_NAME_LENGTH = 21
export const MAX_ESTIMATE_LENGTH = 21
const LOCAL_STORAGE_KEY = 'player'

let sendPlayerPropertiesTimer
let clearedByClearAllEstimatesFlag = false

function App() {
  // This is all the state
  const [isConnected, setIsConnected] = useState(socket.connected)
  const [player, setPlayer] = useState((typeof window.localStorage[LOCAL_STORAGE_KEY] !== 'undefined') ? JSON.parse(window.localStorage[LOCAL_STORAGE_KEY]) : {
    id: null,
    name: 'New player',
    status: 'observer',
    estimate: ''
  })
  const [gameState, setGameState] = useState({
    reveal: false,
    nrParticipants: 0,
    nrObservers: 0,
    nrEstimates: 0,
    playing: false,
    complete: false,
    countdown: null
  })
  const [participants, setParticipants] = useState([])

  const sortParticipants = (list) => {
    if (typeof list !== 'object') {
      return list
    }
    return list.sort((a, b) => {
      if (a.status === 'observer') {
        return 1;
      } else if (b.status === 'observer') {
        return -1;
      }
      if (a.name === null) {
        return 1;
      } else if (b.name === null) {
        return -1;
      }

      return (a.name > b.name);
    });
  }

  // This makes sure new player properties are propagated to the server
  useEffect(() => {
    // If the estimate was cleared by the clearAllEstimates event, don't propagate the change back to the server, he already knows
    if (clearedByClearAllEstimatesFlag) {
      clearedByClearAllEstimatesFlag = false
      return
    }

    clearTimeout(sendPlayerPropertiesTimer)
    sendPlayerPropertiesTimer = setTimeout(() => {
      socket.emit(COMMAND_SET_PLAYER_PROPERTIES, {
        name: player.name,
        status: player.status,
        estimate: player.estimate
      })
    }, 333)

    window.localStorage[LOCAL_STORAGE_KEY] = JSON.stringify({
      id: player.id,
      name: player.name,
      status: player.status,
      estimate: ''
    });
  }, [player])

  // This handles the Socket IO integration
  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true)
    }

    const onDisconnect = () => {
      setIsConnected(false)
    }

    const onEventStartup = (data) => {
      setGameState(gameState => ({
        ...gameState,
        reveal: data.serverState.reveal,
        nrParticipants: data.serverState.nrParticipants,
        nrObservers: data.serverState.nrObservers,
        nrEstimates: data.serverState.nrEstimates,
        playing: data.serverState.playing,
        complete: data.serverState.complete,
        countdown: data.serverState.countdown
      }))
      setPlayer(player => ({
        ...player,
        id: data.id
      }))
      setParticipants(sortParticipants(data.players))
    }

    const onEventPlayerPropertiesSet = (data) => {
      setParticipants((participants) => {
        const newParticipants = structuredClone(participants)
        newParticipants.forEach((participant) => {
          if (participant.id === data.id) {
            if (data.name !== undefined) {
              participant.name = data.name;
            }
            if (data.status !== undefined) {
              participant.status = data.status;
            }
            if (data.estimate !== undefined) {
              participant.estimate = data.estimate;
            }
          }
        })
        return sortParticipants(newParticipants)
      })
    }

    const onEventNewPlayer = (data) => {
      setParticipants((participants) => {
        let newParticipants = structuredClone(participants)
        let idFound = false;
        newParticipants.forEach((player) => {
          if (player.id === data.id) {
            player = data
            idFound = true
          }
        });
        if (!idFound) {
          newParticipants.push(data)
        }
        return sortParticipants(newParticipants)
      })
    }

    const onEventPlayerLeft = (data) => {
      setParticipants(participants => participants.filter((player) => (player.id !== data.id)))
    }

    const onEventNewGameState = (data) => {
      setGameState(gameState => ({
        ...gameState,
        reveal: data.reveal,
        nrParticipants: data.nrParticipants,
        nrObservers: data.nrObservers,
        nrEstimates: data.nrEstimates,
        playing: data.playing,
        complete: data.complete,
        countdown: data.countdown
      }))
    }

    const onEventClearAllEstimates = () => {
      setPlayer((player) => {
        if (player.estimate !== '') {
          clearedByClearAllEstimatesFlag = true
          return {
            ...player,
            estimate: ''
          }
        }
        return player
      })
      setParticipants((participants) => {
        let newParticipants = structuredClone(participants)
        newParticipants.forEach((participant) => {
          participant.estimate = null
        })
        return newParticipants
      })
    }

    const eventHandlers = [
      ['connect', onConnect],
      ['disconnect', onDisconnect],
      [EVENT_STARTUP, onEventStartup],
      [EVENT_PLAYER_PROPERTIES_SET, onEventPlayerPropertiesSet],
      [EVENT_NEW_PLAYER, onEventNewPlayer],
      [EVENT_PLAYER_LEFT, onEventPlayerLeft],
      [EVENT_NEW_GAME_STATE, onEventNewGameState],
      [EVENT_CLEAR_ALL_ESTIMATES, onEventClearAllEstimates],
    ];

    eventHandlers.forEach((settings) => {
      socket.on(settings[0], settings[1])
    })

    return () => {
      eventHandlers.forEach((settings) => {
        socket.off(settings[0], settings[1])
      })
    }

  }, []);

  return (
    <main>
      <div className='container main-container'>
        <div className='columns'>
          <div className='column is-half-tablet is-two-fifths-widescreen'>
            <div className="mb-4">
              <p className="title is-1">
                Ad hoc Estimate
              </p>
              <p className="subtitle is-5 mb-0">
                Agile planning poker made quick and easy
              </p>
            </div>
            <PlayerControls player={player} setPlayer={setPlayer} />
            <PhaseControls player={player} participants={participants} gameState={gameState} />
            <div className='attribution is-hidden-mobile'>
              Made by <a href="https://www.depaul.nl" target="_blank" rel="noreferrer">dePaul Programming</a> &middot; <ConnectionState isConnected={isConnected} />
            </div>
          </div>
          <div className='column'>
            <Participants player={player} participants={participants} gameState={gameState} />
          </div>
        </div>
        <div className='attribution is-hidden-tablet'>
          Made by <a href="https://www.depaul.nl" target="_blank" rel="noreferrer">dePaul Programming</a> &middot; <ConnectionState isConnected={isConnected} />
        </div>
      </div>
    </main>
  );
}

export default App;
