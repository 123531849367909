import ParticipantCard from './ParticipantCard';

function Participants(props) {
    const players = props.participants
    const player = props.player
    const gameState = props.gameState

    const name = player.name
    let playerName
    if (name === '') {
        playerName = <span>You <small className='has-text-danger'>(No name yet!)</small></span>
    } else {
        playerName = <span>You <small><i>({name})</i></small></span>
    }
    let participantBlocks = [
        <ParticipantCard gameState={gameState} key="you" name={playerName} estimate={player.estimate} playerStatus={player.status} />
    ]
    players.forEach((participant, index) => {
        if (typeof participant.name === 'undefined') {
            return
        }
        participantBlocks.push(
            <ParticipantCard gameState={gameState} key={index} name={participant.name} estimate={participant.estimate} playerStatus={participant.status} />
        )
    })

    const title = (players.length > 0) ? (players.length+1) + " Participants" : "Participants"

    return (
        <div className="participants-container">
            <p className="subtitle is-4 mb-4">{title}</p>
            <div className="participants content columns is-mobile is-multiline">
                {(players.length === 0)
                    ? <div className='column'><i>When other participants join, they will appear here&hellip;</i></div>
                    : participantBlocks
                }
            </div>
        </div>
    )
}

export default Participants;